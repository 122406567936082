.footer-container{
    display:flex;
    justify-content: center;
    flex-direction: column;
    /* align-items: center; */
}

@media (min-width: 768px){
    .footer-container{
        display:flex;
        justify-content:center;
        flex-direction: row;
        text-align: justify;
        flex-wrap: nowrap;
    }
    
}
@media (max-width:768px) and (min-height:800px){
    .footer-container{
        display:flex;
        justify-content:center;
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

/* @media (min-height:800px){
    .footer-container{
        display:flex;
        justify-content:center;
        flex-direction: column;
        flex-wrap: nowrap;
    }
} */